<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
        <div class="sm:max-w-xl md:max-w-full lg:max-w-screen-2xl">
          <div class="p-4 text-gray-100">
            <div>
              <a
                v-on:click="$router.go(-1)"
                class="flex items-center space-x-2 text-center cursor-pointer"
                ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
              >
            </div>
          </div>
          <div
            class="px-4 sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-10 lg:px-8 lg:py-10"
          >
            <div class="mt-5">
              <div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <div>
                  <div class="flex flex-col items-center">
                    <img :src="prompt.img_1" class="" style="width: 80%" />
                  </div>

                  <div class="flex flex-col items-center mt-3">
                    <h3
                      class="text-2xl font-bold lg:text-3xl xl:text-3xl text-transparent bg-clip-text bg-gradient-to-r from-green-200 via-pink-100 to-purple-300 text-shadow-white"
                    >
                      <i
                        v-if="prompt.audit == 'approve'"
                        class="fa fa-check-square text-green-500"
                      ></i>

                      {{ prompt.title }}
                    </h3>

                    <b
                      v-if="prompt.audit == 'wait approve'"
                      class="mt-5 text-yellow-300 text-2xl"
                    >
                      wait approve
                    </b>

                    <b
                      class="mt-5 text-red-300 text-2xl"
                      v-if="prompt.audit == 'reject'"
                    >
                      Reject by admin
                    </b>

                    <div>
                      <h3
                        class="text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-gray-300 mt-2 border border-green-300 p-2"
                      >
                        {{ prompt.prompt_type }}
                      </h3>
                    </div>

                    <div class="text-gray-200 w-full mt-3">
                      <div class="grid grid-cols-1 gap-4 lg:grid-cols-3">
                        <div class="">
                          <div class="flex">
                            <h3 class="text-xs font-bold p-2">
                              {{ countWord(prompt.prompt) }} words
                            </h3>

                            <h3 class="text-xs font-bold p-2">
                              Test <i class="fa fa-check-square ml-1"></i>
                            </h3>

                            <h3 class="text-xs font-bold p-2">
                              Tips <i class="fa fa-check-square ml-1"></i>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="text-gray-200 w-full mt-2">
                      <div class="grid grid-cols-1 gap-4 lg:grid-cols-3">
                        <div class="col-span-2">
                          <div class="flex">
                            <h3 class="text-sm font-bold p-2">
                              <i class="fas fa-tags"></i>

                              {{ userSeller.sold }}
                            </h3>

                            <div class="cursor-pointer">
                              <router-link
                                :to="'/account/' + userSeller.address"
                                class="rounded bg-gradient-to-r from-blue-600 to-green-600 p-1"
                                v-if="userSeller.name && userSeller.name != ''"
                              >
                                Seller:
                                {{ userSeller.name }}
                              </router-link>
                              <a
                                :href="'/account/' + userSeller.address"
                                class="rounded bg-gradient-to-r from-blue-600 to-green-600 p-1"
                                v-else
                              >
                                Seller:
                                {{ prompt.address.slice(0, 5) }} ...
                                {{
                                  prompt.address.slice(
                                    prompt.address.length - 5,
                                    prompt.address.length
                                  )
                                }}
                              </a>
                            </div>
                          </div>
                        </div>

                        <div class="">
                          <div class="flex">
                            <h3 class="text-xs font-bold p-2">
                              No reviews yet
                            </h3>
                            <h3 class="text-sm font-bold p-2">
                              <i class="fas fa-eye"></i>

                              {{ prompt.view }}
                            </h3>

                            <h3
                              class="text-sm font-bold p-2 cursor-pointer"
                              @click="favoriteItem()"
                            >
                              <i
                                class="fas fa-heart text-red-500"
                                v-if="showFavorite"
                              ></i>
                              <i class="fas fa-heart" v-else></i>

                              {{ prompt.favorite }}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700" />

                  <!-- <h3
                    class="text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-3"
                  >
                    {{ prompt.description }}
                  </h3> -->

                  <div class="flex justify-center">
                    <textarea
                      v-model="textDescription"
                      rows="5"
                      disabled
                      class="flex-grow outline-none bg-transparent text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-blue-300 mt-3"
                    ></textarea>
                  </div>

                  <div class="text-gray-200 mt-5" v-if="buy == false">
                    <h3 class="text-2xl font-bold">
                      {{ prompt.price }}
                    </h3>

                    <a v-if="getUser">
                      <b
                        v-if="prompt.audit == 'wait approve'"
                        class="mt-5 text-yellow-300 text-2xl"
                      >
                        wait approve
                      </b>

                      <b
                        class="mt-5 text-red-300 text-2xl"
                        v-else-if="prompt.audit == 'reject'"
                      >
                        Reject by admin
                        <br />
                        <b class="text-sm text-white">
                          {{ prompt.rejectDetail }}
                        </b>
                      </b>
                      <b v-else>
                        <a
                          :href="'/pay/' + prompt._id"
                          v-if="prompt.address != getUser.address"
                        >
                          <button
                            class="w-42 mt-2 flex-none text-xl text-base px-6 py-2 border rounded border-none bg-gradient-to-r from-green-700 via-green-500 to-green-700 text-gray-200"
                          >
                            Get Prompt
                          </button>
                        </a>
                        <a v-else>
                          <button
                            @click="showError('You is seller')"
                            class="w-42 mt-2 flex-none text-xl text-base px-6 py-2 border rounded border-none bg-gradient-to-r from-green-700 via-green-500 to-green-700 text-gray-200"
                          >
                            Get Prompt
                          </button>
                        </a>
                      </b>
                    </a>

                    <a v-else>
                      <button
                        @click="showError('Please connect wallet')"
                        class="w-42 mt-2 flex-none text-xl text-base px-6 py-2 border rounded text-gray-700 border-none bg-gradient-to-r from-gray-400 via-gray-300 to-gray-400"
                      >
                        Get Prompt
                      </button>
                    </a>

                    <h3
                      class="text-xs font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-gray-300 mt-2"
                    >
                      After purchasing, you will gain access to the prompt file,
                      which you can use with Midjourney. You must already have
                      access to Midjourney to use this prompt.
                    </h3>
                  </div>
                  <div v-else>
                    <h3 class="text-2xl font-bold text-gray-200 mt-10">
                      Prompt
                    </h3>
                    <h3
                      class="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-gray-300"
                    >
                      {{ prompt.prompt }}
                    </h3>

                    <div v-if="prompt.prompt_instructions != null">
                      <h3 class="text-2xl font-bold text-gray-200 mt-5">
                        Prompt Instructions
                      </h3>
                      <h3
                        class="text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-gray-300"
                      >
                        {{ prompt.prompt_instructions }}
                      </h3>
                    </div>

                    <div v-if="prompt.prompt_type == 'Stable Diffusion'">
                      <h3 class="text-lg font-bold text-gray-200 mt-10">
                        Model
                      </h3>
                      <h3
                        class="text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-gray-300"
                      >
                        {{ prompt.model_name }}
                      </h3>

                      <a
                        v-if="prompt.url_download != null"
                        :href="prompt.url_download"
                        target="_blank"
                      >
                        <button
                          class="w-42 mt-2 flex-none text-xl text-base px-6 py-2 border rounded text-gray-700 border-none bg-gradient-to-r from-green-600 via-green-200 to-green-600"
                        >
                          Download
                        </button>
                      </a>

                      <h3 class="text-lg font-bold text-gray-200 mt-5">
                        Sampler
                      </h3>
                      <h3
                        class="text-sm font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-100 to-gray-300"
                      >
                        {{ prompt.sampler }}
                      </h3>

                      <h3 class="text-sm font-bold text-gray-200 mt-5">
                        Image width
                      </h3>
                      <vue-slider
                        v-model="prompt.imgWidht"
                        v-bind="options"
                        class="sm:max-w-xl md:w-full lg:max-w-screen-sm"
                      ></vue-slider>
                      <h3 class="text-sm font-bold text-gray-200 mt-1">
                        Image Height
                      </h3>
                      <vue-slider
                        v-model="prompt.imgHeight"
                        v-bind="options"
                        class="sm:max-w-xl md:w-full lg:max-w-screen-sm"
                      ></vue-slider>
                      <h3 class="text-sm font-bold text-gray-200 mt-1">
                        Cfg Scale
                      </h3>
                      <vue-slider
                        v-model="prompt.cfgScale"
                        class="sm:max-w-xl md:w-full lg:max-w-screen-sm"
                      ></vue-slider>
                      <h3 class="text-sm font-bold text-gray-200 mt-1">
                        Steps
                      </h3>
                      <vue-slider
                        v-model="prompt.steps"
                        class="sm:max-w-xl md:w-full lg:max-w-screen-sm"
                      ></vue-slider>

                      <div v-if="prompt.sampler">
                        <h3 class="text-lg font-bold text-gray-200 mt-5">
                          Sampler
                        </h3>

                        <div
                          class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg max-w-xl mt-2"
                        >
                          <input
                            v-model="prompt.sampler"
                            placeholder="Random seed"
                            class="flex-grow outline-none text-sm bg-transparent text-gray-100"
                          />
                        </div>
                      </div>

                      <div v-if="prompt.seed">
                        <h3 class="text-lg font-bold text-gray-200 mt-5">
                          Seed
                        </h3>

                        <div
                          class="flex justify-center border-2 py-1 sm:py-3 px-3 rounded-lg max-w-xl mt-2"
                        >
                          <input
                            v-model="prompt.seed"
                            placeholder="Random seed"
                            class="flex-grow outline-none text-sm bg-transparent text-gray-100"
                          />
                        </div>
                      </div>

                      <h3 class="text-sm font-bold text-gray-100 mt-5">
                        CLIP Guidance
                      </h3>
                      <input type="checkbox" v-model="prompt.CLIP" />

                      <div v-if="prompt.negative_prompt">
                        <h3 class="text-lg font-bold text-gray-100 mt-5">
                          Negative Prompt
                        </h3>

                        <h3 class="text-sm font-bold text-gray-100">
                          {{ prompt.negative_prompt }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="">
                  <div class="overflow-scroll h-screen">
                    <div
                      class="grid grid-cols-3 gap-1 lg:grid-cols-3 md:grid-cols-3"
                    >
                      <img
                        :src="prompt.img_2"
                        v-if="prompt.img_2"
                        class="p-0.5"
                      />
                      <img
                        :src="prompt.img_3"
                        v-if="prompt.img_3"
                        class="p-0.5"
                      />
                      <img
                        :src="prompt.img_4"
                        v-if="prompt.img_4"
                        class="p-0.5"
                      />
                      <img
                        :src="prompt.img_5"
                        v-if="prompt.img_5"
                        class="p-0.5"
                      />
                      <img
                        :src="prompt.img_6"
                        v-if="prompt.img_6"
                        class="p-0.5"
                      />
                      <img
                        :src="prompt.img_7"
                        v-if="prompt.img_7"
                        class="p-0.5"
                      />
                    </div>

                    <img :src="prompt.img_2" v-if="prompt.img_2" class="mt-5" />
                    <img :src="prompt.img_3" v-if="prompt.img_3" class="mt-3" />
                    <img :src="prompt.img_4" v-if="prompt.img_4" class="mt-3" />
                    <img :src="prompt.img_5" v-if="prompt.img_5" class="mt-3" />
                    <img :src="prompt.img_6" v-if="prompt.img_6" class="mt-3" />
                    <img :src="prompt.img_7" v-if="prompt.img_7" class="mt-3" />
                    <img :src="prompt.img_1" v-if="prompt.img_1" class="mt-3" />
                  </div>
                </div>
              </div>

              <div
                class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
              >
                <div
                  class="text-gray-200 mt-10"
                  v-if="promptAllByAddress.length > 0"
                >
                  <a
                    :href="'/search/' + userSeller.address"
                    class="text-lg font-bold xl:text-xl text-transparent bg-clip-text bg-gradient-to-r from-green-200 via-pink-500 to-blue-500 transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer text-shadow-white"
                  >
                    More from @ {{ prompt.address.slice(0, 5) }} ...
                    {{
                      prompt.address.slice(
                        prompt.address.length - 5,
                        prompt.address.length
                      )
                    }}
                  </a>

                  <hr class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700" />

                  <div
                    class="overflow-x-auto hidden lg:block"
                    v-if="promptAllByAddress.length < 6"
                  >
                    <div
                      class="grid grid-cols-2 gap-6 lg:grid-cols-5 md:grid-cols-4"
                    >
                      <div
                        class="p-0.5 bg-opacity-25 bg-gradient-to-r from-purple-500 via-pink-600 to-purple-300 rounded"
                        v-for="s in promptAllByAddress"
                        :key="s"
                      >
                        <div class="text-sm border-none rounded bg-gray-900">
                          <div>
                            <a :href="linkDetail(s)">
                              <!-- <img
                                :src="s.img_1"
                                alt="Image 1"
                                class="w-full h-full object-cover transition duration-500 ease-in-out transform hover:scale-110"
                              />
                              <img
                                :src="s.img_1"
                                alt="Image 2"
                                class="w-full h-full object-cover transition duration-500 ease-in-out transform scale-110 hover:scale-100"
                              /> -->
                              <img :src="s.img_1" />
                              <div class="flex">
                                <div class="flex-none">
                                  <h4 class="text-xs p-2 mt-1 text-gray-200">
                                    ⛵ {{ s.title.slice(0, 12) }} ...
                                  </h4>
                                </div>

                                <div
                                  class="flex-grow p-2 text-right text-gray-200 text-sm hidden sm:block"
                                >
                                  {{ s.price }}
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Carousel
                    v-else
                    class="hidden lg:block"
                    :items-to-show="5.5"
                    :itemsToScroll="2"
                  >
                    <Slide
                      v-for="s in promptAllByAddress"
                      :key="s"
                      class="transition duration-300"
                    >
                      <div
                        class="p-0.5 bg-opacity-25 bg-gradient-to-r from-purple-500 via-pink-600 to-purple-300 rounded"
                      >
                        <div class="text-sm border-none rounded bg-gray-900">
                          <div>
                            <a :href="linkDetail(s)">
                              <img :src="s.img_1" class="w-56" />
                              <div class="flex">
                                <div class="flex-none">
                                  <h4 class="text-xs p-2 mt-1 text-gray-200">
                                    ⛵ {{ s.title.slice(0, 12) }} ...
                                  </h4>
                                </div>

                                <div
                                  class="flex-grow p-2 text-right text-gray-200 text-sm hidden sm:block"
                                >
                                  {{ s.price }}
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </Slide>

                    <template #addons>
                      <navigation>
                        <template #next>
                          <div
                            class="bg-gray-700 hover:bg-gray-900 bg-opacity-50"
                          >
                            <i
                              class="fas fa-angle-right p-2"
                              style="font-size: 50px; color: white"
                            ></i>
                          </div>
                        </template>
                        <template #prev>
                          <div
                            class="bg-gray-700 hover:bg-gray-900 bg-opacity-50"
                          >
                            <i
                              class="fas fa-angle-left p-2"
                              style="font-size: 50px; color: white"
                            ></i>
                          </div>
                        </template>
                      </navigation>
                    </template>
                  </Carousel>

                  <Carousel :items-to-show="2.5" class="lg:hidden md:block">
                    <Slide
                      v-for="s in promptAllByAddress"
                      :key="s"
                      :wrap-around="true"
                      class="transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer"
                    >
                      <div
                        class="p-0.5 bg-opacity-25 bg-gradient-to-r from-purple-500 via-pink-600 to-purple-300 rounded m-1"
                      >
                        <div class="text-sm border-none rounded bg-gray-900">
                          <a :href="linkDetail(s)">
                            <img :src="s.img_1" />

                            <div class="flex">
                              <div class="flex-none">
                                <h4 class="text-xs p-2 mt-1 text-gray-200">
                                  ⛵ {{ s.title.slice(0, 12) }} ...
                                </h4>
                              </div>

                              <div
                                class="flex-grow p-2 text-right text-gray-200 text-sm hidden sm:block"
                              >
                                {{ s.price }}
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </Slide>
                    <template #addons>
                      <navigation>
                        <template #next>
                          <div
                            class="bg-gray-700 hover:bg-gray-900 bg-opacity-50"
                          >
                            <i
                              class="fas fa-angle-right p-2"
                              style="font-size: 30px; color: white"
                            ></i>
                          </div>
                        </template>
                        <template #prev>
                          <div
                            class="bg-gray-700 hover:bg-gray-900 bg-opacity-50"
                          >
                            <i
                              class="fas fa-angle-left p-2"
                              style="font-size: 30px; color: white"
                            ></i>
                          </div>
                        </template>
                      </navigation>
                    </template>
                  </Carousel>
                </div>
              </div>

              <Featured_Prompts />
              <Trending_Prompts />
              <Newest_Prompts />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";

import Featured_Prompts from "./recommends/Featured_Prompts.vue";
import Newest_Prompts from "./recommends/Newest_Prompts.vue";
import Trending_Prompts from "./recommends/Trending_Prompts.vue";
import Swal from "sweetalert2";

import { mapGetters, mapActions } from "vuex";

import Prompt from "@/api/prompt";

import nativeToast from "native-toast";

import { POOL_ADDRESS, VERSION } from "../../config";

import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

// import { products, main_product, products_2 } from "../../products";

export default {
  data() {
    return {
      version: VERSION,
      products: [],
      products_2: [],

      _id: this.$route.params._id,
      prompt: {},
      promptAllByAddress: [],
      buy: false,
      userSeller: {},
      showFavorite: false,
      textDescription: "",
      options: {
        min: 0,
        max: 2048,
      },
    };
  },
  components: {
    ConnectWallet,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Featured_Prompts,
    Trending_Prompts,
    Newest_Prompts,
    VueSlider,
  },
  computed: mapGetters(["getBalance", "getCurrency", "getUser"]),

  methods: {
    fixed(a) {
      return (a.toFixed(0) / 1).toLocaleString();
    },
    digit(a, digi) {
      if (a) {
        return a.toLocaleString(undefined, {
          minimumFractionDigits: digi,
        });
      }
    },

    getPrompt() {
      Prompt.byId(this._id)
        .then((res) => {
          this.prompt = res.data.prompt;

          this.promptAllByAddress = res.data.promptAllByAddress;

          this.textDescription = res.data.prompt.ArrayDescription[0];

          this.userSeller = res.data.userSeller;

          var a = this.prompt.favorite_list.filter((w) => {
            return this.getUser.address == w;
          });

          if (a.length != 0) {
            this.showFavorite = true;
          }

          this.BuyerPrompt();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async BuyerPrompt() {
      if (this.getUser) {
        await Prompt.buyer({
          address: this.getUser.address,
        })
          .then((res) => {
            res.data.prompt.map((r) => {
              if (r.prompt._id == this.prompt._id) {
                this.buy = true;
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    showError(text) {
      nativeToast({
        message: `${text}`,
        position: "top",
        timeout: 3000,
        type: "error",
      });
    },
    async favoriteItem() {
      if (this.getUser.address) {
        var a = this.prompt.favorite_list.filter((w) => {
          return this.getUser.address == w;
        });

        if (a.length == 0) {
          await Prompt.favorite({
            _id: this.prompt._id,
            address: this.getUser.address,
          })
            .then((res) => {
              this.getPrompt();
            })
            .catch((error) => {
              // console.log(error);
            });
        }
      }
    },
    countWord(myText) {
      if (myText) {
        myText = myText.trim();

        let wordsArray = myText.split(" ");

        let wordCount = wordsArray.length;

        return wordCount;
      }
    },
    linkDetail(s) {
      if (s.price == "free") {
        return "/promptFree/" + s._id;
      } else {
        return "/prompt/" + s._id;
      }
    },
  },
  created() {
    this.getPrompt();

    if (this.$route.params.ref) {
      localStorage.setItem("Ref", JSON.stringify(this.$route.params.ref));
    } else {
      localStorage.setItem("Ref", JSON.stringify(POOL_ADDRESS));
    }
  },
};
</script>
